import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"20px 0 20px -10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      background: "",
      layout: "prev, pager, next, jumper, sizes",
      "default-current-page": 1,
      "page-size": _ctx.perPage,
      "prev-text": "<",
      "next-text": ">",
      "current-page": _ctx.currentPage,
      total: _ctx.totalItem,
      "pager-count": 11,
      "hide-on-single-page": false,
      onSizeChange: _ctx.onPageSizeChange,
      onCurrentChange: _ctx.onCurrentPageChange
    }, null, 8, ["page-size", "current-page", "total", "onSizeChange", "onCurrentChange"])
  ]))
}