
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "BasicSearch",
  components: {},
  emits: ["search"],
  props: {
    placeholder: {
      type: String,
      default: "Tìm kiếm",
    },
    value: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const boxInputValue = ref("");

    const onSearch = () => {
      emit("search", boxInputValue.value);
    };

    onMounted(() => {
      boxInputValue.value = props.value;
    });

    return {
      boxInputValue,
      onSearch,
    };
  },
});
