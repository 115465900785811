
import { NewsActions } from "@/store/vm/news/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";
import Pagination from "@/components/pagination/Pagination.vue";
import Search from "@/components/search/Basic.vue";

export default defineComponent({
  name: "news-list",
  components: {
    Pagination,
    Search,
  },
  props: {
    widgetClasses: String,
  },
  emits: ["del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();

    const getListNews = (perPage, currentPage, textSearch) => {
      store
        .dispatch(NewsActions.GET_NEWS_LIST, {
          perPage,
          currentPage,
          textSearch,
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const list = computed(() => {
      return store.state.NewsModule.list;
    });

    // Pagination
    const perPage = computed(() => {
      return store.state.NewsModule.perPage;
    });

    const currentPage = computed(() => {
      return store.state.NewsModule.currentPage;
    });

    const totalItem = computed(() => {
      return store.state.NewsModule.totalItem;
    });

    const textSearch = computed(() => {
      return store.state.NewsModule.textSearch;
    });

    // Handler
    const onPageSizeChange = (pageSize) => {
      if (perPage.value !== pageSize) {
        getListNews(pageSize, currentPage.value, textSearch.value);
      }
    };

    const onCurrentPageChange = (targetPage) => {
      if (currentPage.value != targetPage) {
        getListNews(perPage.value, targetPage, textSearch.value);
      }
    };

    const onSearch = (txt) => {
      if (textSearch.value != txt) {
        getListNews(perPage.value, 1, txt);
      }
    };

    const delNews = (newsId) => {
      emit("del", newsId);
    };

    const modalUpsertId = store.state.NewsModule.modalUpsertId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    onMounted(() => {
      getListNews(perPage.value, currentPage.value, "");
    });

    return {
      list,
      checked,
      delNews,
      modalUpsertId,
      lang,
      moment,
      perPage,
      currentPage,
      totalItem,
      onPageSizeChange,
      onCurrentPageChange,
      textSearch,
      onSearch,
    };
  },
});
