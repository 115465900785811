import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted mt-1 fw-bold fs-6" }
const _hoisted_5 = {
  class: "card-toolbar",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "top",
  "data-bs-trigger": "hover",
  title: "Click to add a user"
}
const _hoisted_6 = { class: "btn btn-sm btn-light-primary" }
const _hoisted_7 = { class: "svg-icon svg-icon-3" }
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "row g-9 mb-7" }
const _hoisted_10 = { class: "col-md-8 fv-row" }
const _hoisted_11 = { class: "col-md-4 fv-row" }
const _hoisted_12 = { class: "card-body py-3" }
const _hoisted_13 = { class: "table-responsive" }
const _hoisted_14 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_15 = { class: "fw-bolder text-muted" }
const _hoisted_16 = { class: "min-w-25px" }
const _hoisted_17 = { class: "min-w-25px" }
const _hoisted_18 = {
  class: "min-w-150px",
  style: {"width":"450px","max-width":"450px"}
}
const _hoisted_19 = { class: "min-w-140px" }
const _hoisted_20 = { class: "min-w-140px" }
const _hoisted_21 = { class: "min-w-100px text-end" }
const _hoisted_22 = { class: "d-flex align-items-center" }
const _hoisted_23 = { class: "d-flex align-items-center" }
const _hoisted_24 = { class: "" }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_28 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_29 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_30 = { class: "d-flex align-items-center" }
const _hoisted_31 = { class: "text-dark text-hover-primary fs-6" }
const _hoisted_32 = { class: "text-end" }
const _hoisted_33 = { class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" }
const _hoisted_34 = { class: "svg-icon svg-icon-3" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Search = _resolveComponent("Search")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("news.list")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("common.count")) + ": " + _toDisplayString(_ctx.totalItem), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, { to: { name: 'NewsUpsert', params: { newsId: 'create' } } }, {
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("common.create")), 1)
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Pagination, {
            perPage: _ctx.perPage,
            currentPage: _ctx.currentPage,
            totalItem: _ctx.totalItem,
            onSizeChange: _ctx.onPageSizeChange,
            onCurrentChange: _ctx.onCurrentPageChange
          }, null, 8, ["perPage", "currentPage", "totalItem", "onSizeChange", "onCurrentChange"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Search, {
            value: _ctx.textSearch,
            placeholder: "Tìm kiếm theo tiêu đề",
            onSearch: _ctx.onSearch
          }, null, 8, ["value", "onSearch"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("table", _hoisted_14, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_15, [
              _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("common.order")), 1),
              _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t("news.cover")), 1),
              _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t("news.title")), 1),
              _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t("news.status")), 1),
              _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t("news.created_at")), 1),
              _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("common.action")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(index + 1 + _ctx.perPage * (_ctx.currentPage - 1)), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("img", {
                        src: item.cover,
                        alt: "",
                        style: {"height":"95px"}
                      }, null, 8, _hoisted_25)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("span", _hoisted_28, _toDisplayString(item.title), 1),
                      _createElementVNode("span", _hoisted_29, _toDisplayString(item.desp), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.moment(String(item.created_at)).format("DD/MM/YYYY")), 1)
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass([item.status === 1 ? 'text-muted' : 'text-success', "fw-bold d-block fs-7"])
                  }, _toDisplayString(item.status === 1 ? "inactive" : "active"), 3)
                ]),
                _createElementVNode("td", _hoisted_32, [
                  _createVNode(_component_router_link, {
                    to: { name: 'NewsUpsert', params: { newsId: item.id } }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tooltip, {
                        class: "item",
                        effect: "light",
                        content: _ctx.lang.edit,
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", _hoisted_33, [
                            _createElementVNode("span", _hoisted_34, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["content"])
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _createVNode(_component_el_tooltip, {
                    class: "item",
                    effect: "light",
                    content: _ctx.lang.delete,
                    placement: "top"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", {
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                        onClick: ($event: any) => (_ctx.delNews(item.id))
                      }, [
                        _createElementVNode("span", _hoisted_36, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                        ])
                      ], 8, _hoisted_35)
                    ]),
                    _: 2
                  }, 1032, ["content"])
                ])
              ]))
            }), 128))
          ])
        ])
      ]),
      _createVNode(_component_Pagination, {
        perPage: _ctx.perPage,
        currentPage: _ctx.currentPage,
        totalItem: _ctx.totalItem,
        onSizeChange: _ctx.onPageSizeChange,
        onCurrentChange: _ctx.onCurrentPageChange
      }, null, 8, ["perPage", "currentPage", "totalItem", "onSizeChange", "onCurrentChange"])
    ])
  ], 2))
}