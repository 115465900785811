
import { defineComponent } from "vue";

export default defineComponent({
  name: "pagination",
  components: {},
  emits: ["size-change", "current-change"],
  data() {
    return {
      loadingModule: true,
    };
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItem: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const onPageSizeChange = (pageSize) => {
      emit("size-change", pageSize);
    };

    const onCurrentPageChange = (targetPage) => {
      emit("current-change", targetPage);
    };

    return {
      onPageSizeChange,
      onCurrentPageChange,
    };
  },
});
