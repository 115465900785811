
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/news/List.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { NewsActions, NewsGetters } from "@/store/vm/news/enums";

interface ComponentData {
  newsIdModal: number | boolean;
}
export default defineComponent({
  name: "news",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
  },
  data(): ComponentData {
    return {
      newsIdModal: false,
    };
  },
  methods: {
    onUpsert(newsId: number | boolean) {
      this.newsIdModal = newsId;
    },
    onReset() {
      this.newsIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Tin tức", ["Marketing"]);
      MenuComponent.reinitialization();
    });

    const onDel = (newsId) => {
      const detail = store.getters[NewsGetters.GET_BY_ID](newsId);

      Swal.fire({
        title: "Bạn muốn xóa tin tức?",
        text: detail.title,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(newsId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (newsId) => {
      return store.dispatch(NewsActions.DELETE, newsId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
